import React from "react"

import Footer from "../components/footer"
import SEO from "../components/seo"
import Analytics from "../components/analytics"
import Link from "../components/link"
import SignUpForm from "../components/sign-up-form"

const UpdatePage = () => {
  return (
    <>
      <Analytics />
      <SEO title="Update | Testing LiveView" />
      <div className="relative overflow-hidden">
        <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
          <main className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-12">
                <a href="/">
                  <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
                    Testing <span className="text-orange-500"> LiveView</span>
                  </h2>
                </a>
                <div className="my-20 flex text-lg">
                  <div className="mx-auto">
                    <SignUpForm />
                  </div>
                </div>
              </div>
            </div>
            <div className="prose mx-auto max-w-xl">
              <p>The new course will be a complete rewrite.</p>

              <p>
                I’m taking what I’ve learned from making the original course and
                applying that to make this version even better.
              </p>

              <h3 id="short-lessons-that-pack-a-punch">
                Short lessons that pack a punch.
              </h3>

              <p>
                I have <em>always</em> respected your time. For that reason, I
                don’t like adding filler material (or leaving my “ums”, “uhs”,
                and typos) just so the course runtime looks impressive.
              </p>

              <p>
                I know you’re a busy person and that your time is valuable. So,
                I want to teach you how to test LiveView effectively in as
                little time as possible.
              </p>

              <p>
                The original course already had almost{" "}
                <a href="https://twitter.com/CodiferousCoder/status/1416441619419652099">
                  zero fluff
                </a>
                . But I’m taking that a step further and removing all
                unnecessary material so we can focus on the essentials.
              </p>

              <p>
                Thus, each lesson will be a short, independent lesson that packs
                a punch – something you can watch on your commute to work, while
                you grab your lunch, or within the first ten minutes of sitting
                down to work.
              </p>

              <p className="font-semibold inline-block">
                It’ll be the fastest way to learn how to test LiveView
                effectively.{" "}
                <span role="img" aria-label="lightning bolt">
                  ⚡️
                </span>
              </p>

              <h3 id="free-upgrade">Free upgrade!</h3>

              <p>
                All current customers of Testing LiveView will get the upgrade
                for free!
              </p>

              <p>
                So, if you’re a current customer (or thinking about becoming
                one), rest assured that you’ll be covered with all the latest
                and greatest.{" "}
                <span
                  className="inline-block"
                  role="img"
                  aria-label="fist bump"
                >
                  🤜🤛
                </span>
              </p>

              <p>
                <Link to="#sign-up-form">Sign up</Link> to get updates, or{" "}
                <Link to="https://courses.germanvelasco.com/testing-liveview/buy">
                  buy the course now
                </Link>
                .
              </p>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default UpdatePage
