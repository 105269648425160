import React from "react"

const Footer = () => (
  <footer className="text-center px-4 sm:px-6 py-8 mx-auto text-gray-500">
    <div className="block flex text-md">
      <div className="mx-auto">
        © {new Date().getFullYear()}, German Velasco
      </div>
    </div>
  </footer>
)

export default Footer
