import React from "react"
import Helmet from "react-helmet"

function Analytics() {
  return process.env.GATSBY_PRODUCTION ? (
    <Helmet
      script={[
        {
          src: "https://cdn.usefathom.com/script.js",
          "data-spa": "auto",
          "data-site": "HMXJSZJY",
          defer: true,
        },
      ]}
    />
  ) : (
    ""
  )
}

export default Analytics
