import React, { useState } from "react"

const SignUpForm = () => {
  const [submitted, setSubmitted] = useState(false)
  const [email, setEmail] = useState("")
  const action = "https://app.convertkit.com/forms/1723000/subscriptions"
  const emailFieldName = "email_address"

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitted(true)

    let data = new FormData()
    data.append(emailFieldName, email)

    let request = new XMLHttpRequest()
    request.open("POST", action)
    request.send(data)
  }

  const handleEmailChange = e => {
    setEmail(e.target.value)
  }

  let output = submitted ? (
    <ThankYouMessage email={email} />
  ) : (
    <div id="sign-up-form" className="space-y-2">
      <p className="text-base font-bold text-gray-900 sm:text-lg">
        Phoenix 1.7. LiveView 0.18.
      </p>
      <p className="text-base text-gray-900 sm:text-lg">
        Sign up to hear when the update is coming...
      </p>
      <form className="pt-5 sm:flex" onSubmit={handleSubmit}>
        <input
          type="email"
          required={true}
          placeholder="Enter your email"
          value={email}
          onChange={handleEmailChange}
          aria-label="Email"
          className="appearance-none block w-full px-3 py-3 border border-gray-700 text-base leading-6 rounded-md placeholder-gray-700 shadow-sm focus:outline-none focus:placeholder-gray-400 focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:flex-1"
        />
        <button
          type="submit"
          className="mt-3 w-full px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-700 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray active:bg-gray-900 transition duration-150 ease-in-out sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
        >
          Sign Up
        </button>
      </form>
    </div>
  )

  return output
}

const ThankYouMessage = ({ email }) => (
  <p className="mt-2 text-base font-medium text-gray-900">
    Thank you {email}! Please check your inbox to confirm your email.
  </p>
)

export default SignUpForm
